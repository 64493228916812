:root {
  /* --background: linear-gradient(148deg, #FFF 3.67%, #E2EDFF 78.61%); */
  --foreground: 240 10% 3.9%;

  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 46.1%;

  --popover: 0 0% 100%;
  --popover-foreground: 240 10% 3.9%;

  --card: 0 0% 100%;
  --card-foreground: 240 10% 3.9%;

  --border: 240 5.9% 90%;
  --input: 240 5.9% 90%;

  --primary: 240 5.9% 10%;
  --primary-foreground: 0 0% 98%;

  --secondary: 240 4.8% 95.9%;
  --secondary-foreground: 240 5.9% 10%;

  --brand: 240 4.8% 95.9%;
  --brand-foreground: 240 5.9% 10%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;

  --ring: 240 5.9% 10%;

  --radius: 0.5rem;
  --color-primary: rgba(0, 0, 0, 1);
  --color-primary-contrast: rgb(255, 255, 255);
  --color-secondary: rgba(0, 0, 0, 0.7);
  --color-secondary-contrast: rgba(255, 255, 255, 0.70);
  --color-tertiary: rgba(0, 0, 0, 0.5);
  --container-foreground-tertiary: rgba(0, 0, 0, 0.50);
  --status-Generic-Beige-Background-2: #d7d4d4;
  --status-Generic-Beige-Stroke-Active-Rest: #7a7574;
   --container-background-primary: #fffc;
   --button-background: black;
   --button-border-color: #f2f2f2;
   --button-color: white;
   --menu-hover:rgba(255, 255, 255, 0.766);
  --container-background-seconday: rgba(64, 128, 191, 1);
   --status-generic-cornflower-background-2-rest: #c8d1fa;
   --status-generic-navy-stroke-active-rest: #0027b4;
   --status-Generic-Beige-Background-2-rest: #d7d4d4;
   --status-generic-beige-foreground-2-rest: #444241;
   --status-generic-peach-foreground-2-rest: #FFDDB3;
   --gradient-secondary:linear-gradient(312deg, #D6DEFF 12.16%, #747EB5 67.59%, #6E4291 94.73%);
  --container-border: #f2f2f2;
  --container-divider: #f2f2f2;
  --temp-theme-background: linear-gradient(282deg, rgba(240, 205, 224, 0.90) -37.25%, rgba(163, 207, 243, 0.90) 85.2%);
  --temp-container-highlight: rgba(44, 83, 202, 0.05);

  --background-success: linear-gradient(104deg, #3D953B 10.06%, #45D920 97.18%);
  --background-danger: linear-gradient(293deg, #F77 0%, #EF234C 100%);
  --background-secondary: linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.05) 100%);
  --container-hightlight: rgba(44, 83, 202, 0.05);
  /* --background-modal: linear-gradient(0deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.40) 100%), linear-gradient(180deg, rgba(248, 248, 255, 0.60) 0%, rgba(248, 248, 255, 0.50) 100%); */
  --background-modal: rgb(255, 255, 255);

}

.theme-default {
  --background: #F5F6F9;
  /* --background: linear-gradient(148deg, #FFF 3.67%, #E2EDFF 78.61%); */
}

.dark .theme-default {
  --background: #F5F6F9;
  /* --background: linear-gradient(148deg, #FFF 3.67%, #E2EDFF 78.61%); */
}

.theme-theme_1 {
  /*  this is for demo purpose modify themes according to names of theme */
  --background: linear-gradient(148deg, #e80addbf 3.67%, #e2ecff 78.61%);
}

.dark .theme_1 {
  /*  this is for demo purpose modify themes according to names of theme */
  --background: linear-gradient(148deg, #FFF 3.67%, #E2EDFF 78.61%);
}

.theme-horizon {
  --background: linear-gradient(113deg, #A0CCEE 15.91%, #FEA9A2 90.17%);
}

.dark .theme-horizon {
  --background: linear-gradient(113deg, #A0CCEE 15.91%, #FEA9A2 90.17%);
}

.theme-golden_dawn {
  --background: linear-gradient(328deg, #FDF5A1 -0.93%, #F2D4E9 85.07%);
}

.dark .theme-golden_dawn {
  --background: linear-gradient(328deg, #FDF5A1 -0.93%, #F2D4E9 85.07%);
}

.theme-aqua_breeze {
  --background: linear-gradient(226deg, #B8FFEE 18.6%, #B8FFEE 18.6%, #96E0FF 96.7%);
}

.dark .theme-aqua_breeze {
  --background: linear-gradient(226deg, #B8FFEE 18.6%, #B8FFEE 18.6%, #96E0FF 96.7%);
}

.theme-pastel_serenity {
  --background: linear-gradient(144deg, #E7BBF7 15.67%, #A3E7F3 99.19%);
}

.dark .theme-pastel_serenity {
  --background: linear-gradient(144deg, #E7BBF7 15.67%, #A3E7F3 99.19%);
}

.theme-cotton_candy_sky {
  --background: linear-gradient(179deg, #59CDEB 1.23%, #F8CFD2 91.54%)
}

.dark .theme-cotton_candy_sky {
  --background: linear-gradient(179deg, #59CDEB 1.23%, #F8CFD2 91.54%)
}

.theme-minty_rose {
  --background: linear-gradient(133deg, #FECDEB 18.71%, #D0FBEF 96.6%)
}

.dark .theme-minty_rose {
  --background: linear-gradient(133deg, #FECDEB 18.71%, #D0FBEF 96.6%)
}

.theme-lavender_mist {
  --background: linear-gradient(133deg, #D1D6FA 18.71%, #FAD2D1 96.6%)
}

.dark .theme-lavender_mist {
  --background: linear-gradient(133deg, #D1D6FA 18.71%, #FAD2D1 96.6%)
}

/* pastel colors end */

/*  Candy Themes */
.theme-mystic_aura {
  --background: conic-gradient(from 56deg at 50% 50%, #99FDFF 0deg, #D1B3FF 360deg)
}

.dark .theme-mystic_aura {
  --background: conic-gradient(from 56deg at 50% 50%, #99FDFF 0deg, #D1B3FF 360deg)
}

.theme-lemonade_bliss {
  --background: conic-gradient(from 129deg at 50% 50%, #F5FFA8 0deg, #9EFFD8 360deg)
}

.dark .theme-lemonade_bliss {
  --background: conic-gradient(from 129deg at 50% 50%, #F5FFA8 0deg, #9EFFD8 360deg)
}

.theme-lemonade_bliss {
  --background: conic-gradient(from 129deg at 50% 50%, #F5FFA8 0deg, #9EFFD8 360deg)
}

.dark .theme-lemonade_bliss {
  --background: conic-gradient(from 129deg at 50% 50%, #F5FFA8 0deg, #9EFFD8 360deg)
}

.theme-cotton_candy_dream {
  --background: conic-gradient(from -14deg at 55.22% 52.35%, #FFC7FB 0deg, #A3CEFF 360deg)
}

.dark .theme-cotton_candy_dream {
  --background: conic-gradient(from -14deg at 55.22% 52.35%, #FFC7FB 0deg, #A3CEFF 360deg)
}

.theme-fresh_petals {
  --background: conic-gradient(from 107deg at 46.74% 40.04%, #FFC7FB 4.802233725786209deg, #C2FFCD 360deg)
}

.dark .theme-fresh_petals {
  --background: conic-gradient(from 107deg at 46.74% 40.04%, #FFC7FB 4.802233725786209deg, #C2FFCD 360deg)
}

.theme-violet_blush {
  --background: conic-gradient(from 170deg at 46.74% 40.04%, #FFC7CA 4.802233725786209deg, #E299FF 360deg)
}

.dark .theme-violet_blush {
  --background: conic-gradient(from 170deg at 46.74% 40.04%, #FFC7CA 4.802233725786209deg, #E299FF 360deg)
}

/* candy theme end */

/* Key Themes */
.theme-blue_horizon {
  --background: linear-gradient(135deg, #B5D9FF 18.31%, #61ACFE 99.28%)
}

.dark .theme-blue_horizon {
  --background: linear-gradient(135deg, #B5D9FF 18.31%, #61ACFE 99.28%)
}

.theme-cyan_serenity {
  --background: linear-gradient(90deg, #89DEEF 0%, #51B4D7 100%)
}

.dark .theme-cyan_serenity {
  --background: linear-gradient(90deg, #89DEEF 0%, #51B4D7 100%)
}

.theme-powder_blue {
  --background: #C8E1FF
}

.dark .theme-powder_blue {
  --background: #C8E1FF
}

/* Key Themes end */

/*  Earth Tomes */
.theme-earthy_calm {
  --background: #EACAB3
}

.dark .theme-earthy_calm {
  --background: #EACAB3
}

.theme-earthy_embrace {
  --background: #D0AA8C
}

.dark .theme-earthy_embrace {
  --background: #D0AA8C
}

.theme-soft_stone {
  --background: #DDDCD0
}

.dark .theme-soft_stone {
  --background: #DDDCD0
}

.theme-forest_fog {
  --background: #BBB9A5
}

.dark .theme-forest_fog {
  --background: #BBB9A5
}

/* earthy tones end */

/* Solid Colors */
.theme-soft_violet {
  --background: #DCD6F2
}

.dark .theme-soft_violet {
  --background: #DCD6F2
}

.theme-rose_quartz {
  --background: #F4AACA
}

.dark .theme-rose_quartz {
  --background: #F4AACA
}

.theme-pale_petal {
  --background: #F4E0E4
}

.dark .theme-pale_petal {
  --background: #F4E0E4
}

.theme-lilac_breeze {
  --background: #D8C5F4
}

.dark .theme-lilac_breeze {
  --background: #D8C5F4
}

.theme-fresh_meadow {
  --background: #A6DEC7
}

.dark .theme-fresh_meadow {
  --background: #A6DEC7
}

.theme-golden_glow {
  --background: #ECD99F
}

.dark .theme-golden_glow {
  --background: #ECD99F
}

.theme-vintage_pink {
  --background: #DFB5C1
}

.dark .theme-vintage_pink {
  --background: #DFB5C1
}

/* solid colors end */


/*  Popular Themes */

.theme-blissful_dawn{
  --background:linear-gradient(159.74deg, #9ED1FF 10.96%, #FFE88D 89.04%)
}
.dark .theme-blissful_dawn{
  --background:linear-gradient(159.74deg, #9ED1FF 10.96%, #FFE88D 89.04%)
}

.theme-bay_breeze{
  --background:linear-gradient(144.78deg, #FFC31B 16.23%, #004AAE 45.63%)
}
.dark .theme-bay_breeze{
  --background:linear-gradient(144.78deg, #FFC31B 16.23%, #004AAE 45.63%)
}

.theme-golden_bear_horizon{
  --background:linear-gradient(160.89deg, #F2B844 10.45%, #0B2571 44.73%)
}
.dark .theme-golden_bear_horizon{
  --background:linear-gradient(160.89deg, #F2B844 10.45%, #0B2571 44.73%)
}

.theme-seafoam_serenity {
  --background: linear-gradient(343deg, #ACB6E5 10.02%, #86FDE8 89.98%)
}

.dark .theme-seafoam_serenity {
  --background: linear-gradient(343deg, #ACB6E5 10.02%, #86FDE8 89.98%)
}

.theme-lavender_dawn {
  --background: linear-gradient(90deg, #9796F0 0%, #FBC7D4 100%)
}

.dark .theme-lavender_dawn {
  --background: linear-gradient(90deg, #9796F0 0%, #FBC7D4 100%)
}

.theme-periwinkle_dream {
  --background: linear-gradient(282deg, rgba(240, 205, 224, 0.90) -37.25%, rgba(163, 207, 243, 0.90) 85.2%)
}

.dark .theme-periwinkle_dream {
  --background: linear-gradient(282deg, rgba(240, 205, 224, 0.90) -37.25%, rgba(163, 207, 243, 0.90) 85.2%)
}

.theme-peach_glow {
  --background: linear-gradient(282deg, rgba(240, 205, 224, 0.90) -37.25%, rgba(255, 195, 160, 0.90) 85.2%)
}

.dark .theme-peach_glow {
  --background: linear-gradient(282deg, rgba(240, 205, 224, 0.90) -37.25%, rgba(255, 195, 160, 0.90) 85.2%)
}

/* Popular themes end */

.theme-zinc {
  --background: #F5F6F9;
}

.dark .theme-zinc {
  --background: #F5F6F9;
}

.theme-slate {
  --background: #2d7abd33;
}

.dark .theme-slate {
  --background: #2d7abd33;
}

.theme-stone {
  --background: #2d7abd33;
}

.dark .theme-stone {
  --background: #2d7abd33;
}

.theme-gray {
  --background: linear-gradient(290deg, #e55d87 16.31%, #5fc3e4 83.36%);

}

.dark .theme-gray {
  --background: linear-gradient(290deg, #e55d87 16.31%, #5fc3e4 83.36%);

}

.theme-neutral {
  --background: linear-gradient(290deg, #b7abc7 16.31%, #cf8bf3 83.36%);
}

.dark .theme-neutral {
  --background: linear-gradient(290deg, #b7abc7 16.31%, #cf8bf3 83.36%);
}

/* latin-ext */
@font-face {
  font-family: '__DM_Sans_abd653';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_abd653';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_abd653';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_abd653';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_abd653';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_abd653';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_abd653';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_abd653';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_abd653';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_abd653';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_abd653';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_abd653';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_abd653';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_abd653';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_abd653';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_abd653';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_abd653';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_abd653';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Sans_Fallback_abd653';src: local("Arial");ascent-override: 94.90%;descent-override: 29.66%;line-gap-override: 0.00%;size-adjust: 104.53%
}.__className_abd653 {font-family: '__DM_Sans_abd653', '__DM_Sans_Fallback_abd653';font-style: normal
}

/* CSS variables. */
:root {
	--PhoneInput-color--focus: #03b2cb;
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
	--PhoneInputCountrySelect-marginRight: 0.35em;
	--PhoneInputCountrySelectArrow-width: 0.3em;
	--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	--PhoneInputCountrySelectArrow-borderWidth: 1px;
	--PhoneInputCountrySelectArrow-opacity: 0.45;
	--PhoneInputCountrySelectArrow-color: currentColor;
	--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountrySelectArrow-transform: rotate(45deg);
	--PhoneInputCountryFlag-aspectRatio: 1.5;
	--PhoneInputCountryFlag-height: 1em;
	--PhoneInputCountryFlag-borderWidth: 1px;
	--PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
	--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);
}

.PhoneInput {
	/* This is done to stretch the contents of this component. */
	display: flex;
	align-items: center;
}

.PhoneInputInput {
	/* The phone number input stretches to fill all empty space */
	flex: 1;
	/* The phone number input should shrink
	   to make room for the extension input */
	min-width: 0;
}

.PhoneInputCountryIcon {
	width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
	height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
	width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
	/* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
	background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
	/* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
	/* Fixes weird vertical space above the flag icon. */
	/* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
	display: block;
	/* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
	width: 100%;
	height: 100%;
}

.PhoneInputInternationalIconPhone {
	opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
	opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
	margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
	cursor: default;
}

.PhoneInputCountrySelectArrow {
	display: block;
	content: '';
	width: var(--PhoneInputCountrySelectArrow-width);
	height: var(--PhoneInputCountrySelectArrow-width);
	margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
	border-style: solid;
	border-color: var(--PhoneInputCountrySelectArrow-color);
	border-top-width: 0;
	border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	border-left-width: 0;
	border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	transform: var(--PhoneInputCountrySelectArrow-transform);
	opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}
.tippy-box[data-animation=fade][data-state=hidden]{opacity:0}[data-tippy-root]{max-width:calc(100vw - 10px)}.tippy-box{position:relative;background-color:#333;color:#fff;border-radius:4px;font-size:14px;line-height:1.4;white-space:normal;outline:0;transition-property:transform,visibility,opacity}.tippy-box[data-placement^=top]>.tippy-arrow{bottom:0}.tippy-box[data-placement^=top]>.tippy-arrow:before{bottom:-7px;left:0;border-width:8px 8px 0;border-top-color:initial;transform-origin:center top}.tippy-box[data-placement^=bottom]>.tippy-arrow{top:0}.tippy-box[data-placement^=bottom]>.tippy-arrow:before{top:-7px;left:0;border-width:0 8px 8px;border-bottom-color:initial;transform-origin:center bottom}.tippy-box[data-placement^=left]>.tippy-arrow{right:0}.tippy-box[data-placement^=left]>.tippy-arrow:before{border-width:8px 0 8px 8px;border-left-color:initial;right:-7px;transform-origin:center left}.tippy-box[data-placement^=right]>.tippy-arrow{left:0}.tippy-box[data-placement^=right]>.tippy-arrow:before{left:-7px;border-width:8px 8px 8px 0;border-right-color:initial;transform-origin:center right}.tippy-box[data-inertia][data-state=visible]{transition-timing-function:cubic-bezier(.54,1.5,.38,1.11)}.tippy-arrow{width:16px;height:16px;color:#333}.tippy-arrow:before{content:"";position:absolute;border-color:transparent;border-style:solid}.tippy-content{position:relative;padding:5px 9px;z-index:1}
.advanced-cropper-line-wrapper--north, .advanced-cropper-line-wrapper--south {
  height: 12px;
  width: 100%; }

.advanced-cropper-line-wrapper--north {
  cursor: n-resize; }

.advanced-cropper-line-wrapper--south {
  cursor: s-resize; }

.advanced-cropper-line-wrapper--east, .advanced-cropper-line-wrapper--west {
  width: 12px;
  height: 100%; }

.advanced-cropper-line-wrapper--east {
  cursor: e-resize; }

.advanced-cropper-line-wrapper--west {
  cursor: w-resize; }

.advanced-cropper-line-wrapper--disabled {
  cursor: auto; }

.advanced-cropper-line-wrapper__content {
  position: absolute; }
  .advanced-cropper-line-wrapper__content--east, .advanced-cropper-line-wrapper__content--west {
    height: 100%; }
  .advanced-cropper-line-wrapper__content--north, .advanced-cropper-line-wrapper__content--south {
    width: 100%; }
  .advanced-cropper-line-wrapper__content--east {
    left: 50%;
    transform: translateX(-100%); }
  .advanced-cropper-line-wrapper__content--west {
    right: 50%;
    transform: translateX(100%); }
  .advanced-cropper-line-wrapper__content--north {
    top: 50%; }
  .advanced-cropper-line-wrapper__content--south {
    bottom: 50%; }

.advanced-cropper-handler-wrapper {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px; }
  .advanced-cropper-handler-wrapper__draggable {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .advanced-cropper-handler-wrapper--west-north {
    cursor: nw-resize; }
  .advanced-cropper-handler-wrapper--north {
    cursor: n-resize; }
  .advanced-cropper-handler-wrapper--east-north {
    cursor: ne-resize; }
  .advanced-cropper-handler-wrapper--east {
    cursor: e-resize; }
  .advanced-cropper-handler-wrapper--east-south {
    cursor: se-resize; }
  .advanced-cropper-handler-wrapper--south {
    cursor: s-resize; }
  .advanced-cropper-handler-wrapper--west-south {
    cursor: sw-resize; }
  .advanced-cropper-handler-wrapper--west {
    cursor: w-resize; }
  .advanced-cropper-handler-wrapper--disabled {
    cursor: auto; }

.advanced-cropper-bounding-box {
  position: relative;
  height: 100%;
  width: 100%; }
  .advanced-cropper-bounding-box__handler-wrapper {
    position: absolute; }
    .advanced-cropper-bounding-box__handler-wrapper--east, .advanced-cropper-bounding-box__handler-wrapper--west {
      top: 50%;
      transform: translateY(-50%);
      height: 100%; }
    .advanced-cropper-bounding-box__handler-wrapper--south, .advanced-cropper-bounding-box__handler-wrapper--north {
      left: 50%;
      transform: translateX(-50%);
      width: 100%; }
    .advanced-cropper-bounding-box__handler-wrapper--west, .advanced-cropper-bounding-box__handler-wrapper--west-north, .advanced-cropper-bounding-box__handler-wrapper--west-south {
      left: 0; }
    .advanced-cropper-bounding-box__handler-wrapper--east, .advanced-cropper-bounding-box__handler-wrapper--east-north, .advanced-cropper-bounding-box__handler-wrapper--east-south {
      left: 100%; }
    .advanced-cropper-bounding-box__handler-wrapper--north, .advanced-cropper-bounding-box__handler-wrapper--west-north, .advanced-cropper-bounding-box__handler-wrapper--east-north {
      top: 0; }
    .advanced-cropper-bounding-box__handler-wrapper--south, .advanced-cropper-bounding-box__handler-wrapper--west-south, .advanced-cropper-bounding-box__handler-wrapper--east-south {
      top: 100%; }
  .advanced-cropper-bounding-box__handler {
    position: absolute; }
    .advanced-cropper-bounding-box__handler--west-north {
      left: 0;
      top: 0; }
    .advanced-cropper-bounding-box__handler--north {
      left: 50%;
      top: 0; }
    .advanced-cropper-bounding-box__handler--east-north {
      left: 100%;
      top: 0; }
    .advanced-cropper-bounding-box__handler--east {
      left: 100%;
      top: 50%; }
    .advanced-cropper-bounding-box__handler--east-south {
      left: 100%;
      top: 100%; }
    .advanced-cropper-bounding-box__handler--south {
      left: 50%;
      top: 100%; }
    .advanced-cropper-bounding-box__handler--west-south {
      left: 0;
      top: 100%; }
    .advanced-cropper-bounding-box__handler--west {
      left: 0;
      top: 50%; }
  .advanced-cropper-bounding-box__line {
    position: absolute; }
    .advanced-cropper-bounding-box__line--north, .advanced-cropper-bounding-box__line--south {
      left: 0;
      transform: translateY(-50%); }
    .advanced-cropper-bounding-box__line--north {
      top: 0; }
    .advanced-cropper-bounding-box__line--south {
      top: 100%; }
    .advanced-cropper-bounding-box__line--west, .advanced-cropper-bounding-box__line--east {
      top: 0;
      transform: translateX(-50%); }
    .advanced-cropper-bounding-box__line--west {
      left: 0; }
    .advanced-cropper-bounding-box__line--east {
      left: 100%; }

.advanced-cropper-artificial-transition {
  will-change: transform; }

.advanced-cropper-background-image {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: absolute;
  transform-origin: center;
  pointer-events: none;
  max-width: none !important; }

.advanced-cropper-canvas {
  display: none; }

.advanced-cropper-source {
  width: 1px;
  height: 1px;
  visibility: hidden;
  position: absolute;
  opacity: 0; }

.advanced-cropper-fade {
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  display: flex;
  flex-direction: column; }
  .advanced-cropper-fade--visible {
    opacity: 1;
    visibility: visible; }

.advanced-cropper-wrapper__fade {
  flex-grow: 1;
  min-height: 0; }

.advanced-cropper-stencil-grid {
  display: table;
  border-collapse: collapse;
  table-layout: fixed;
  opacity: 0;
  transition: opacity 0.3s; }
  .advanced-cropper-stencil-grid--visible {
    opacity: 1; }
  .advanced-cropper-stencil-grid__row {
    display: table-row; }
  .advanced-cropper-stencil-grid__cell {
    display: table-cell;
    width: 1%;
    height: 1%;
    border: currentColor solid 1px; }
    .advanced-cropper-stencil-grid__cell--top {
      border-top-color: transparent; }
    .advanced-cropper-stencil-grid__cell--left {
      border-left-color: transparent; }
    .advanced-cropper-stencil-grid__cell--right {
      border-right-color: transparent; }
    .advanced-cropper-stencil-grid__cell--bottom {
      border-bottom-color: transparent; }

.advanced-cropper-stencil-overlay {
  box-sizing: content-box;
  box-shadow: 0 0 0 1000px currentColor;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-backface-visibility: hidden; }

.advanced-cropper-stencil-wrapper {
  will-change: transform; }

.advanced-cropper-boundary {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  direction: ltr;
  position: relative; }
  .advanced-cropper-boundary__content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .advanced-cropper-boundary__stretcher {
    pointer-events: none;
    position: relative;
    max-width: 100%;
    max-height: 100%; }

.advanced-cropper-circle-stencil {
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: content-box;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d; }
  .advanced-cropper-circle-stencil__overlay {
    border-radius: 50%;
    overflow: hidden; }
  .advanced-cropper-circle-stencil__preview {
    border-radius: 50%; }
  .advanced-cropper-circle-stencil__draggable-area, .advanced-cropper-circle-stencil__overlay, .advanced-cropper-circle-stencil__preview, .advanced-cropper-circle-stencil__grid {
    position: absolute;
    height: 100%;
    width: 100%; }
  .advanced-cropper-circle-stencil--movable {
    cursor: move; }

.advanced-cropper-rectangle-stencil {
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d; }
  .advanced-cropper-rectangle-stencil__draggable-area, .advanced-cropper-rectangle-stencil__overlay, .advanced-cropper-rectangle-stencil__preview, .advanced-cropper-rectangle-stencil__grid {
    position: absolute;
    height: 100%;
    width: 100%; }
  .advanced-cropper-rectangle-stencil--movable {
    cursor: move; }

.advanced-cropper-simple-line {
  background: none;
  border-width: 0; }
  .advanced-cropper-simple-line--south, .advanced-cropper-simple-line--north {
    height: 0;
    width: 100%; }
  .advanced-cropper-simple-line--east, .advanced-cropper-simple-line--west {
    height: 100%;
    width: 0; }
  .advanced-cropper-simple-line--east {
    border-right-width: 1px; }
  .advanced-cropper-simple-line--west {
    border-left-width: 1px; }
  .advanced-cropper-simple-line--south {
    border-bottom-width: 1px; }
  .advanced-cropper-simple-line--north {
    border-top-width: 1px; }

.advanced-cropper-simple-handler {
  display: block; }

.advanced-cropper-preview {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column; }
  .advanced-cropper-preview__content {
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .advanced-cropper-preview__image {
    display: none;
    pointer-events: none;
    position: absolute;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    transform-origin: center;
    max-width: none !important; }
    .advanced-cropper-preview__image--visible {
      display: block; }
  .advanced-cropper-preview__boundary {
    flex-grow: 1;
    min-height: 0;
    min-width: 0; }

.cropper-preview-wrapper__fade {
  flex-grow: 1;
  min-height: 0;
  width: 100%; }

.advanced-cropper {
  overflow: hidden;
  max-height: 100%;
  background: black;
  position: relative;
  display: flex;
  flex-direction: column;
  color: white; }
  .advanced-cropper__boundary {
    flex-grow: 1;
    min-height: 0;
    min-width: 0; }
  .advanced-cropper__wrapper, .advanced-cropper__background-wrapper {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute; }
  .advanced-cropper__stencil-wrapper {
    position: absolute; }
  .advanced-cropper * {
    box-sizing: border-box; }

.advanced-cropper-simple-handler {
  background: currentColor;
  height: 10px;
  width: 10px; }

.advanced-cropper-simple-line {
  transition: border 0.5s;
  border-color: rgba(255, 255, 255, 0.3);
  border-style: solid; }
  .advanced-cropper-simple-line--hover {
    border-color: white; }

.advanced-cropper-circle-stencil__preview {
  border: solid 2px rgba(255, 255, 255, 0.2); }

.advanced-cropper-stencil-overlay {
  color: rgba(0, 0, 0, 0.5); }

.advanced-cropper-stencil-grid {
  color: rgba(255, 255, 255, 0.4); }

