:root {
  /* --background: linear-gradient(148deg, #FFF 3.67%, #E2EDFF 78.61%); */
  --foreground: 240 10% 3.9%;

  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 46.1%;

  --popover: 0 0% 100%;
  --popover-foreground: 240 10% 3.9%;

  --card: 0 0% 100%;
  --card-foreground: 240 10% 3.9%;

  --border: 240 5.9% 90%;
  --input: 240 5.9% 90%;

  --primary: 240 5.9% 10%;
  --primary-foreground: 0 0% 98%;

  --secondary: 240 4.8% 95.9%;
  --secondary-foreground: 240 5.9% 10%;

  --brand: 240 4.8% 95.9%;
  --brand-foreground: 240 5.9% 10%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;

  --ring: 240 5.9% 10%;

  --radius: 0.5rem;
  --color-primary: rgba(0, 0, 0, 1);
  --color-primary-contrast: rgb(255, 255, 255);
  --color-secondary: rgba(0, 0, 0, 0.7);
  --color-secondary-contrast: rgba(255, 255, 255, 0.70);
  --color-tertiary: rgba(0, 0, 0, 0.5);
  --container-foreground-tertiary: rgba(0, 0, 0, 0.50);
  --status-Generic-Beige-Background-2: #d7d4d4;
  --status-Generic-Beige-Stroke-Active-Rest: #7a7574;
   --container-background-primary: #fffc;
   --button-background: black;
   --button-border-color: #f2f2f2;
   --button-color: white;
   --menu-hover:rgba(255, 255, 255, 0.766);
  --container-background-seconday: rgba(64, 128, 191, 1);
   --status-generic-cornflower-background-2-rest: #c8d1fa;
   --status-generic-navy-stroke-active-rest: #0027b4;
   --status-Generic-Beige-Background-2-rest: #d7d4d4;
   --status-generic-beige-foreground-2-rest: #444241;
   --status-generic-peach-foreground-2-rest: #FFDDB3;
   --gradient-secondary:linear-gradient(312deg, #D6DEFF 12.16%, #747EB5 67.59%, #6E4291 94.73%);
  --container-border: #f2f2f2;
  --container-divider: #f2f2f2;
  --temp-theme-background: linear-gradient(282deg, rgba(240, 205, 224, 0.90) -37.25%, rgba(163, 207, 243, 0.90) 85.2%);
  --temp-container-highlight: rgba(44, 83, 202, 0.05);

  --background-success: linear-gradient(104deg, #3D953B 10.06%, #45D920 97.18%);
  --background-danger: linear-gradient(293deg, #F77 0%, #EF234C 100%);
  --background-secondary: linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.05) 100%);
  --container-hightlight: rgba(44, 83, 202, 0.05);
  /* --background-modal: linear-gradient(0deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.40) 100%), linear-gradient(180deg, rgba(248, 248, 255, 0.60) 0%, rgba(248, 248, 255, 0.50) 100%); */
  --background-modal: rgb(255, 255, 255);

}

.theme-default {
  --background: #F5F6F9;
  /* --background: linear-gradient(148deg, #FFF 3.67%, #E2EDFF 78.61%); */
}

.dark .theme-default {
  --background: #F5F6F9;
  /* --background: linear-gradient(148deg, #FFF 3.67%, #E2EDFF 78.61%); */
}

.theme-theme_1 {
  /*  this is for demo purpose modify themes according to names of theme */
  --background: linear-gradient(148deg, #e80addbf 3.67%, #e2ecff 78.61%);
}

.dark .theme_1 {
  /*  this is for demo purpose modify themes according to names of theme */
  --background: linear-gradient(148deg, #FFF 3.67%, #E2EDFF 78.61%);
}

.theme-horizon {
  --background: linear-gradient(113deg, #A0CCEE 15.91%, #FEA9A2 90.17%);
}

.dark .theme-horizon {
  --background: linear-gradient(113deg, #A0CCEE 15.91%, #FEA9A2 90.17%);
}

.theme-golden_dawn {
  --background: linear-gradient(328deg, #FDF5A1 -0.93%, #F2D4E9 85.07%);
}

.dark .theme-golden_dawn {
  --background: linear-gradient(328deg, #FDF5A1 -0.93%, #F2D4E9 85.07%);
}

.theme-aqua_breeze {
  --background: linear-gradient(226deg, #B8FFEE 18.6%, #B8FFEE 18.6%, #96E0FF 96.7%);
}

.dark .theme-aqua_breeze {
  --background: linear-gradient(226deg, #B8FFEE 18.6%, #B8FFEE 18.6%, #96E0FF 96.7%);
}

.theme-pastel_serenity {
  --background: linear-gradient(144deg, #E7BBF7 15.67%, #A3E7F3 99.19%);
}

.dark .theme-pastel_serenity {
  --background: linear-gradient(144deg, #E7BBF7 15.67%, #A3E7F3 99.19%);
}

.theme-cotton_candy_sky {
  --background: linear-gradient(179deg, #59CDEB 1.23%, #F8CFD2 91.54%)
}

.dark .theme-cotton_candy_sky {
  --background: linear-gradient(179deg, #59CDEB 1.23%, #F8CFD2 91.54%)
}

.theme-minty_rose {
  --background: linear-gradient(133deg, #FECDEB 18.71%, #D0FBEF 96.6%)
}

.dark .theme-minty_rose {
  --background: linear-gradient(133deg, #FECDEB 18.71%, #D0FBEF 96.6%)
}

.theme-lavender_mist {
  --background: linear-gradient(133deg, #D1D6FA 18.71%, #FAD2D1 96.6%)
}

.dark .theme-lavender_mist {
  --background: linear-gradient(133deg, #D1D6FA 18.71%, #FAD2D1 96.6%)
}

/* pastel colors end */

/*  Candy Themes */
.theme-mystic_aura {
  --background: conic-gradient(from 56deg at 50% 50%, #99FDFF 0deg, #D1B3FF 360deg)
}

.dark .theme-mystic_aura {
  --background: conic-gradient(from 56deg at 50% 50%, #99FDFF 0deg, #D1B3FF 360deg)
}

.theme-lemonade_bliss {
  --background: conic-gradient(from 129deg at 50% 50%, #F5FFA8 0deg, #9EFFD8 360deg)
}

.dark .theme-lemonade_bliss {
  --background: conic-gradient(from 129deg at 50% 50%, #F5FFA8 0deg, #9EFFD8 360deg)
}

.theme-lemonade_bliss {
  --background: conic-gradient(from 129deg at 50% 50%, #F5FFA8 0deg, #9EFFD8 360deg)
}

.dark .theme-lemonade_bliss {
  --background: conic-gradient(from 129deg at 50% 50%, #F5FFA8 0deg, #9EFFD8 360deg)
}

.theme-cotton_candy_dream {
  --background: conic-gradient(from -14deg at 55.22% 52.35%, #FFC7FB 0deg, #A3CEFF 360deg)
}

.dark .theme-cotton_candy_dream {
  --background: conic-gradient(from -14deg at 55.22% 52.35%, #FFC7FB 0deg, #A3CEFF 360deg)
}

.theme-fresh_petals {
  --background: conic-gradient(from 107deg at 46.74% 40.04%, #FFC7FB 4.802233725786209deg, #C2FFCD 360deg)
}

.dark .theme-fresh_petals {
  --background: conic-gradient(from 107deg at 46.74% 40.04%, #FFC7FB 4.802233725786209deg, #C2FFCD 360deg)
}

.theme-violet_blush {
  --background: conic-gradient(from 170deg at 46.74% 40.04%, #FFC7CA 4.802233725786209deg, #E299FF 360deg)
}

.dark .theme-violet_blush {
  --background: conic-gradient(from 170deg at 46.74% 40.04%, #FFC7CA 4.802233725786209deg, #E299FF 360deg)
}

/* candy theme end */

/* Key Themes */
.theme-blue_horizon {
  --background: linear-gradient(135deg, #B5D9FF 18.31%, #61ACFE 99.28%)
}

.dark .theme-blue_horizon {
  --background: linear-gradient(135deg, #B5D9FF 18.31%, #61ACFE 99.28%)
}

.theme-cyan_serenity {
  --background: linear-gradient(90deg, #89DEEF 0%, #51B4D7 100%)
}

.dark .theme-cyan_serenity {
  --background: linear-gradient(90deg, #89DEEF 0%, #51B4D7 100%)
}

.theme-powder_blue {
  --background: #C8E1FF
}

.dark .theme-powder_blue {
  --background: #C8E1FF
}

/* Key Themes end */

/*  Earth Tomes */
.theme-earthy_calm {
  --background: #EACAB3
}

.dark .theme-earthy_calm {
  --background: #EACAB3
}

.theme-earthy_embrace {
  --background: #D0AA8C
}

.dark .theme-earthy_embrace {
  --background: #D0AA8C
}

.theme-soft_stone {
  --background: #DDDCD0
}

.dark .theme-soft_stone {
  --background: #DDDCD0
}

.theme-forest_fog {
  --background: #BBB9A5
}

.dark .theme-forest_fog {
  --background: #BBB9A5
}

/* earthy tones end */

/* Solid Colors */
.theme-soft_violet {
  --background: #DCD6F2
}

.dark .theme-soft_violet {
  --background: #DCD6F2
}

.theme-rose_quartz {
  --background: #F4AACA
}

.dark .theme-rose_quartz {
  --background: #F4AACA
}

.theme-pale_petal {
  --background: #F4E0E4
}

.dark .theme-pale_petal {
  --background: #F4E0E4
}

.theme-lilac_breeze {
  --background: #D8C5F4
}

.dark .theme-lilac_breeze {
  --background: #D8C5F4
}

.theme-fresh_meadow {
  --background: #A6DEC7
}

.dark .theme-fresh_meadow {
  --background: #A6DEC7
}

.theme-golden_glow {
  --background: #ECD99F
}

.dark .theme-golden_glow {
  --background: #ECD99F
}

.theme-vintage_pink {
  --background: #DFB5C1
}

.dark .theme-vintage_pink {
  --background: #DFB5C1
}

/* solid colors end */


/*  Popular Themes */

.theme-blissful_dawn{
  --background:linear-gradient(159.74deg, #9ED1FF 10.96%, #FFE88D 89.04%)
}
.dark .theme-blissful_dawn{
  --background:linear-gradient(159.74deg, #9ED1FF 10.96%, #FFE88D 89.04%)
}

.theme-bay_breeze{
  --background:linear-gradient(144.78deg, #FFC31B 16.23%, #004AAE 45.63%)
}
.dark .theme-bay_breeze{
  --background:linear-gradient(144.78deg, #FFC31B 16.23%, #004AAE 45.63%)
}

.theme-golden_bear_horizon{
  --background:linear-gradient(160.89deg, #F2B844 10.45%, #0B2571 44.73%)
}
.dark .theme-golden_bear_horizon{
  --background:linear-gradient(160.89deg, #F2B844 10.45%, #0B2571 44.73%)
}

.theme-seafoam_serenity {
  --background: linear-gradient(343deg, #ACB6E5 10.02%, #86FDE8 89.98%)
}

.dark .theme-seafoam_serenity {
  --background: linear-gradient(343deg, #ACB6E5 10.02%, #86FDE8 89.98%)
}

.theme-lavender_dawn {
  --background: linear-gradient(90deg, #9796F0 0%, #FBC7D4 100%)
}

.dark .theme-lavender_dawn {
  --background: linear-gradient(90deg, #9796F0 0%, #FBC7D4 100%)
}

.theme-periwinkle_dream {
  --background: linear-gradient(282deg, rgba(240, 205, 224, 0.90) -37.25%, rgba(163, 207, 243, 0.90) 85.2%)
}

.dark .theme-periwinkle_dream {
  --background: linear-gradient(282deg, rgba(240, 205, 224, 0.90) -37.25%, rgba(163, 207, 243, 0.90) 85.2%)
}

.theme-peach_glow {
  --background: linear-gradient(282deg, rgba(240, 205, 224, 0.90) -37.25%, rgba(255, 195, 160, 0.90) 85.2%)
}

.dark .theme-peach_glow {
  --background: linear-gradient(282deg, rgba(240, 205, 224, 0.90) -37.25%, rgba(255, 195, 160, 0.90) 85.2%)
}

/* Popular themes end */

.theme-zinc {
  --background: #F5F6F9;
}

.dark .theme-zinc {
  --background: #F5F6F9;
}

.theme-slate {
  --background: #2d7abd33;
}

.dark .theme-slate {
  --background: #2d7abd33;
}

.theme-stone {
  --background: #2d7abd33;
}

.dark .theme-stone {
  --background: #2d7abd33;
}

.theme-gray {
  --background: linear-gradient(290deg, #e55d87 16.31%, #5fc3e4 83.36%);

}

.dark .theme-gray {
  --background: linear-gradient(290deg, #e55d87 16.31%, #5fc3e4 83.36%);

}

.theme-neutral {
  --background: linear-gradient(290deg, #b7abc7 16.31%, #cf8bf3 83.36%);
}

.dark .theme-neutral {
  --background: linear-gradient(290deg, #b7abc7 16.31%, #cf8bf3 83.36%);
}
